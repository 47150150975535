import axios from 'axios'

const API = '/zonas/reservas'

const Reservas = {
    listar(params){
        return axios(`${API}`, { params })
    },
    obtenerReserva(id_reserva){
        return axios.get(`${API}/${id_reserva}`)
    },
    cambiarEstado(payload){
        return axios.put(`${API}/estado`, payload)
    },
    ReservasPendientes(params){
        return axios.get(`${API}/listar/calendario`,{params})
    },


}

export default Reservas
